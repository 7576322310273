@import '../../../assets/styles/variables';

.orderMessageContainer {
  display: flex;
  flex-direction: row;
  .bubbleContainer {
    display: flex;
    flex-direction: column;
  }

  .orderText {
    padding-top: 8px;
    font-size: 12pt;
  }

  .spacer {
    flex: 1 1 auto;
  }
    %bubbleDate {
        color: #8c9391;
        font-size: 9pt;
        padding-top: 2px;
        user-select: auto;
    }

    %bubble {
        padding: 10px;
        margin-top: 12px;
        min-width: 120px;
        user-select: auto;
    }

    %bubbleTitle {
        user-select: auto;
    }

    %bubbleText {
        user-select: auto;
    }

    %bubbleBottomLine {
        display: flex;
        flex-direction: row;
        margin-right: 80px;
    }

    .userBubble {
        @extend %bubble;
        background-color: #c3ccd8;
        border-radius: 12px 12px 12px 0px;
        margin-right: 80px;
    }

    .userBubbleTitle {
        @extend %bubbleTitle;
        color: #363636;
        font-weight: bold;
    }

    .userBubbleText {
        @extend %bubbleText;
        color: #434343;
    }

    .userBubbleDate {
        @extend %bubbleDate;
    }

    .userBubbleBottomLine {
        @extend %bubbleBottomLine;
    }

    .systemBubble {
        @extend %bubble;
        background-color: #e3e3e3;
        border-radius: 12px 12px 12px 0px;
        margin-right: 80px;
    }

    .systemBubbleTitle {
        @extend %bubbleTitle;
        color: #363636;
        font-weight: bold;
    }

    .systemBubbleDate {
        @extend %bubbleDate;
    }

    .systemBubbleText {
        @extend %bubbleText;
        color: #434343;
    }

    .systemBubbleBottomLine {
        @extend %bubbleBottomLine;
    }

    .pharmacyBubble {
        @extend %bubble;
        background-color: #a9cfff;
        border-radius: 12px 12px 0px 12px;
        margin-left: 80px;
    }

    .pharmacyBubbleTitle {
        @extend %bubbleTitle;
        color: #363636;
        font-weight: bolder;
    }

    .pharmacyBubbleText {
        @extend %bubbleText;
        color: #434343;
    }

    .pharmacyBubbleDate {
        @extend %bubbleDate;
        align-self: flex-end;
    }

    .pharmacyBubbleBottomLine {
        display: flex;
        flex-direction: row-reverse;
    }

    .questionBubble {
        @extend %bubble;
        background-color: #ffbf3a;
        border-radius: 12px 12px 12px 0px;
        margin-right: 80px;
    }

    .questionBubbleTitle {
        @extend %bubbleTitle;
        color: #363636;
        font-weight: bold;
    }

    .questionBubbleDate {
        @extend %bubbleDate;
    }

    .questionBubbleText {
        @extend %bubbleText;
        color: #363636;
    }

    .questionBubbleDate {
        @extend %bubbleDate;
    }

    .questionBubbleBottomLine {
        @extend %bubbleBottomLine;
    }

    .chatImage {
        max-width: 280px;
        max-height: 280px;
        border-radius: 12px 12px 12px 0px;
    }

    .answer {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        min-width: 40px;
        padding: 8px;
        margin-left: 8px;
        margin-top: 8px;
        background: #dddddd;
        border: 1px solid #909090;
        border-radius: 4px;
    }

    .answerPriority {
        @extend .answer;
        background-color: #fcedcd;
        border: 1px solid #ffbf3a;
    }
}

.orderMessageContainer:last-child {
  margin-bottom: 80px;
}
