@import '../../../assets/styles/variables';

.chatListContainer {
  flex: 0 0 240px;
  max-width: 300px;
  //width: 10em;
  //height: 10em;
  overflow: auto;
  visibility: hidden;
  .chatListContent {
    visibility: visible;
  }

  .chatFilterContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    border-bottom: 1px solid #eee;
    min-height: 20px;
    .sortType {
      display: flex;
      font-size: 9pt;
      align-items: center;
      color: #747474;
    }
  }

}

.chatListContainer:hover,
.chatListContainer:focus {
  visibility: visible;
}

.chatListContainer_delayed {
  transition: visibility 0.2s;
}

.chatListContainer_delayed:hover {
  transition: visibility 0s 0.2s;
}
