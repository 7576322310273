.authDataContainer {
  .row {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 4px 0;
    justify-content: flex-start;

    &.actions {
      justify-content: space-between;
    }

    label {
      width: 120px;
    }

    input[type="text"],
    input[type="password"] {
      padding: 4px;
      width: 200px;
    }

    button {
      border: 1px solid #3cbaa8;
      border-radius: 8px;
      background-color: #FFFFFF;
      cursor: pointer;
      padding: 8px;
      margin: 4px 4px;

      &:hover {
        border: 2px solid #3cbaa8;
      }
    }

    button.cancel {
      border: 1px solid red;
      &:hover {
        border: 2px solid red;
      }
    }
  }

  .error {
    color: red
  }
  .message {
    color: #3cbaa8
  }
}


