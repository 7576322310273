@import '../../../assets/styles/variables';

.chatListEntryContainer {
  padding: 10px 5px;
  border-bottom: 1px solid #eee;
  cursor: pointer;
  position: relative;
  display: flex ;
  flex-direction: column;
  .topRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    //justify-content: space-between;
    .customerName {
      color: #434343;
      font-weight: 500;
    }
    .date {
      color: $text-secondary;
      font-size: 12px;
      position: absolute;
      right: 15px;
    }
  }
  .lastMessage {
    margin-top: 10px;
    margin-left: 25px;
    color: $text-secondary;
    font-size: 14px;
    display: flex;
    justify-content: space-between;
  }

  .bottomRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .unreadBadge {
    height: 18px;
    width: 18px;
    background: #ffbf3a;
    border-radius: 9px;
    align-items: center;
    display: inline-flex;
    flex-direction: row;
    justify-content: center;
    color: #222;
    font-size: 13px;
    /* margin-left: 10px; */
    font-weight: bold;
    position: absolute;
    right: 15px;
  }
}
.chatListEntryContainer:hover {
  background: rgb(246, 246, 246);
}
