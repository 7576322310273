.chatMessagesViewContainer {
    display: flex;
    flex-direction: column;
    flex: 0 1 auto;

    .topHeader {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #ccc;
        position: fixed;
        right: 0;
        left: 240px;
        top: 55px;
        border-right: 1px solid #e0e0e0;
        border-left: 1px solid #e0e0e0;
        background-color: #ededed;
        padding: 10px 16px;
        box-sizing: border-box;
        height: 52px;
    }
    .avatar {
        width: 25px;
        height: 25px;
        border: 5px solid #ffe380;
        border-bottom: 5px solid transparent;
        border-radius: 50%;
        background-color: #42526e;
        margin-right: 15px;
    }
    .chatMessageViewContainer {
        display: flex;
        flex-direction: row;
        //flex: 0 1 auto;
    }
    .adminMsg {
        padding: 10px;
        background-color: #a9cfff;
        border-radius: 12px 12px 0 12px;
        font-size: 15px;
        color: #222;
        box-shadow: 0 1px 5px rgba(0, 0, 0, 0.13);
        max-width: 50%;
    }
    .userMsg {
      padding: 10px;
      background-color: #fff;
      border-radius: 12px 12px 12px 0;
      color: #434343;
      font-size: 15px;
      box-shadow: 0 1px 5px rgba(0, 0, 0, 0.13);
      max-width: 50%;
    }
    .chatDate {
        color: #8c9391;
        font-size: 8pt;
        padding-top: 2px;
        -webkit-user-select: auto;
        user-select: auto;
    }
    .spacer {
        flex: 1 1 auto;
    }
    .messageItemsWrapper {
        display: flex;
        flex-direction: column;
        /* flex: 0 1 auto; */
        margin-top: 55px;
        margin-bottom: 10px;
    }
    .deleteChatIcon {
        cursor: pointer;
    }
    .checkIcon {
        margin-left: 5px;
        padding-top: 2px;
    }
}
