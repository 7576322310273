.optionItemContainer {
    display: flex;
    flex-direction: row;
    height: 24px;
    margin-top: 8px;
    cursor: pointer;

  .text {
    font-size: 12pt;
  }

  .optionNotSelected {
    height: 14px;
    width: 14px;
    border: 1px solid #707070;
    border-radius: 4px;
    margin-right: 12px;
  }

  .optionSelected {
    height: 14px;
    width: 14px;
    border: 1px solid #707070;
    border-radius: 4px;
    margin-right: 12px;
    box-shadow: inset 0px 0px 0px 2px white;
    background: #aedcf2;
  }
}
