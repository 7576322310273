.orderItemsMainWrapper {

  .orderDate {
    color: #8c9391;
    font-size: 9pt;
    padding-top: 2px;
    user-select: auto;
  }
  .orderItemsViewContainer {
    display: flex;
    flex-direction: row;
    flex: 0 1 auto;

    .itemsContainer {
      padding: 10px;
      display: flex;
      flex-direction: column;
      background-color: #c3ccd8;
      border-radius: 12px 12px 12px 0px;
      flex: 0 1 auto;
    }
    .spacer {
      flex: 1 1 auto;
    }
    .imagesContainer {
      display: flex;
      flex-direction: row;
      width: 100%;
      flex-wrap: wrap;
      //justify-content: center;
    }
    .title {
      font-size: 12pt;
      font-weight: bold;
    }
    .paybackContainer {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: 4px;
    }
  }
}
