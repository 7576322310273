@import '../../../assets/styles/variables';

.orderListEntryContainer {
  padding: 10px 5px;
  border-bottom: 1px solid #eee;
  cursor: pointer;
  position: relative;
  display: flex ;
  flex-direction: column;
  .topRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    //justify-content: space-between;
    .customerName {
      color: #434343;
      font-weight: 500;
    }
    .date {
      color: $text-secondary;
      font-size: 12px;
      position: absolute;
      right: 15px;
    }
  }
  .status {
    margin-top: 10px;
    margin-left: 25px;
    color: $text-secondary;
    font-size: 14px;
    display: flex;
    justify-content: space-between;
  }
}
.orderListEntryContainer:hover {
  background: rgb(246, 246, 246);
}
