@import '../../../assets/styles/variables';

.navbarContainer {
    z-index: 1100;
    padding: 15px;
    box-sizing: border-box;
    flex-shrink: 0;
    height: $navbarHeight;
    //overflow: hidden;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    color: #fff;
    background-color: #677490;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
        0px 4px 5px 0px rgba(0, 0, 0, 0.14),
        0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    img {
      height: 30px;
      border-radius: 3px;
      background: #ffffff;
      padding: 5px;
    }
    .navbarBtn {
        padding: 8px 16px;
        //color: #ffffff;
        background: transparent;
        cursor: pointer;
        border: none;
        font-size: 15px;
    }
    .navbarBtn:hover {
        color: $primary;
    }

    /* Style The Dropdown Button */
    .dropbtn {
        background-color: transparent;
        color: white;
        padding: 10px;
        font-size: 16px;
        border: none;
        cursor: pointer;
    }

    /* The container <div> - needed to position the dropdown content */
    .dropdown {
        position: relative;
        display: inline-block;
    }

    /* Dropdown Content (Hidden by Default) */
    .dropdown-content {
        display: none;
        position: absolute;
        right: 0;
        background-color: #f9f9f9;
        min-width: 180px;
        box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
        z-index: 1;
        border-radius: 4px;
        overflow: hidden;
    }

    /* Links inside the dropdown */
    .dropdown-content button {
        color: #434343;
        padding: 12px 16px;
        text-decoration: none;
        display: block;
        width: 100%;
        text-align: left;
    }

    /* Change color of dropdown links on hover */
    .dropdown-content button:hover {
        background-color: #f1f1f1;
    }

    /* Show the dropdown menu on hover */
    .dropdown:hover .dropdown-content {
        display: block;
    }

    /* Change the background color of the dropdown button when the dropdown content is shown */
    .dropdown:hover .dropbtn {
        //background-color: $primary;
        color: $primary;
    }

  button:disabled {
    color: #ccc;
    cursor: not-allowed;
  }
}
