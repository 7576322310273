@import '../../../assets/styles/variables';

.modalContainer {
    .header {
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom: 1px solid $primary;
    }
    img {
        height: 60px;
    }
    .title {
        font-size: 20px;
        font-weight: 400;
        margin-left: 10px;
        color: #363636;
    }
    .headline {
        font-size: 24px;
        font-weight: 400;
        color: #363636;
        text-align: center;
    }
    .description {
        color: #434343;
        font-size: 15px;
    }
    .selectBox {
        border: 1px solid $primary;
        padding: 16px;
        border-radius: 4px;
        margin: 15px 4px 4px;
        position: relative;
        overflow: hidden;
        justify-items: center;
        .desc {
            color: $info;
            font-size: 12px;
        }
        text-align: center;

        input[type='file'] {
            position: absolute;
            bottom: 8px;
            height: 50px;
            min-width: 100%;
            font-size: 100px;
            text-align: right;
            filter: alpha(opacity=0);
            opacity: 0;
            outline: none;
            display: block;
            cursor: pointer;
        }
    }
    .action {
        display: flex;
        margin: 12px 0;
        width: 100%;
        text-align: center;
    }
    button,
    .button {
        display: block;
        border: 1px solid #1484e3;
        background: $primary;
        border-radius: 4px;
        padding: 4px;
        color: #FFFFFF;
        cursor: pointer;
        width: 100%;
    }
    button.cancel {
        color: $info;
        background: none;
        border: 1px solid $primary;
        padding: 4px;
        width: 100%;
    }

    .selectBox.disabled {
        color: #CECECE;
        cursor: not-allowed;
        pointer-events: none;
        border: 1px solid #CECECE;
    }
    .selectBox.disabled .headline,
    .selectBox.disabled .desc {
        color: #CECECE;
    }

    .errorMsg {
        color: $danger;
        font-size: 14px;
        font-weight: bold;
        padding: 20px 5px;
        border-bottom: 1px solid $primary;
    }
    .headline {
        color: $primary;
        margin-bottom: 10px;
        font-size: 15px;
    }

    .supportText {
        font-size: 12px;
    }

    .row {
        display: flex;
        justify-content: center;
        flex-direction: row;
        align-content: center;
    }

    img.icon {
        justify-content: center;
    }
}
