
.orderItemContainer {
  display: flex;
  flex-direction: column;
  padding: 10px;

  .spacer {
    flex: 1 1 auto;
  }
  .prescriptionImage {
    height: 80px;
    border-radius: 12px 12px 12px 0px;
  }
}

.orderText {
  user-select: auto;
  font-size: 12pt;
  font-weight: bold;
}
