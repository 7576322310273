@import '../../../assets/styles/variables';

.loginContainer {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    background-image: url("../../../assets/images/loginBG.jpeg");
    flex-direction: column;
    height: 100%;
    width: 100%;
    font-family: 'Arial', sans-serif;
    align-items: center;
    justify-content: center;
  .container {
    display: flex;
    justify-content: center;
    width: 600px;
    background-color: #ffffff;
    border-radius: 6px;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14),
    0px 2px 1px -1px rgba(0, 0, 0, 0.12);
    padding: 24px;
    .header {
      display: flex;
      justify-content: center;
      align-items: center;
      color: #434343;
      padding: 15px;
      margin: 0 20px;
    }
    img {
      height: 80px;
    }
    .title {
      font-size: 20px;
      font-weight: 400;
      margin-left: 10px;
    }
    .loginForm {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 15px;
      width: 90%;
      input {
        width: 85%;
        padding: 13px;
        margin-top: 10px;
      }
      .loginBtn {
        background: #2699fb;
        color: #ffffff;
        width: 90%;
        padding: 8px;
        margin-top: 20px;
        border-radius: 5px;
        font-size: 1.1rem;
        font-weight: 500;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
        outline: none;
      }
      .loginBtn:hover {
        border: 1px solid #2699fb;
        background: #0e81df;
      }
      .loginBtnDisabled {
        @extend .loginBtn;
        background-color: lightgray;
        transition: all 0.1s ease-in-out;
      }
    }
    .errorMsg {
      display: flex;
      justify-content: center;
      align-items: center;
      color: $danger;
      font-size: 14px;
      font-weight: bold;
      padding: 20px 5px;
      margin: 0 20px;
    }
  }
  .left {
    flex-direction: column;
    width: 70%;
  }
}

.cSafeSubmit {
  margin: 10px 0;
  text-transform: none;
  cursor: pointer;
  display: inline-flex;
  outline: none;
  position: relative;
  align-items: center;
  user-select: none;
  vertical-align: middle;
  justify-content: center;
  text-decoration: none;
  padding: 6px 16px;
  font-size: 0.875rem;
  min-width: 64px;
  box-sizing: border-box;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-family: "Barlow", sans-serif;
  font-weight: 500;
  line-height: 1.75;
  border-radius: 4px;
  letter-spacing: 0.02857em;
  width: 100%;
  color: #fff;
  background-color: #2699fb;
  border: none;
}

.vitusSubmitDiv,
.loginWithEmailDiv,
.backDiv{
  display: flex;
  justify-content: center;
  align-items: center;
}

.loginWithEmailBtn,
.backBtn {
  color: #CECECE;
  background: none;
  border: none;
  text-decoration: underline;
  padding: 8px 0px;
}
.loginWithEmailBtn:hover {
  color: #909090;
  border: 0;
}
.backBtn:hover {
  color: #909090;
  border: 0;
}

.vitusSubmit {
  justify-content: center;
  align-items: center;
  margin: 10px 0;
  text-transform: none;
  cursor: pointer;
  position: relative;
  user-select: none;
  text-decoration: none;
  padding: 6px 16px;
  font-size: 0.875rem;
  min-width: 64px;
  box-sizing: border-box;
  font-family: "Barlow", sans-serif;
  font-weight: 500;
  line-height: 1.75;
  border-radius: 4px;
  letter-spacing: 0.02857em;
  width: 100%;
  color: #fff;
  background-color: #2699fb;
  border: none;
}
.vitusSubmit:hover {
  border: 1px solid #1484e3;
  background: #0e81df;
}

h3.subTitle {
  display: flex;
  justify-content: center;
  color: #2699fb;
}

.right {
  justify-content: center;
  align-content: center;
  .motif {
    height: 200px !important;
    width: 200px;
  }
}
