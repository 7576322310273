@import "../../../assets/styles/variables";

.inputText {
  transition: all 0.30s ease-in-out;
  outline: none;
  //padding: 3px 0px 3px 3px;
  //margin: 5px 1px 3px 0px;
  border: 1px solid $secondary;
  border-radius: 3px;
}

.inputTextError {
  transition: all 0.30s ease-in-out;
  outline: none;
  padding: 3px 0px 3px 3px;
  margin: 5px 1px 3px 0px;
  border: 1px solid $secondary;
}

.inputText:focus {
  box-shadow: 0 0 5px $primary;
  //padding: 3px 0px 3px 3px;
  //margin: 5px 1px 3px 0px;
  border: 1px solid $primary;
}

.inputTextError:focus {
  box-shadow: 0 0 5px $primary;
  padding: 3px 0px 3px 3px;
  margin: 5px 1px 3px 0px;
  border: 1px solid $primary;
}

.inputText::placeholder {
  color: $text-secondary;
}

.inputTextError::placeholder {
  color: $danger;
}