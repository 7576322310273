.orderStatusChangeContainer {
  .title {
    text-align: center;
    font-weight: bold;
    padding-bottom: 15px;
  }
  .modalStyle {
    width: 250px;
    left: calc(50% - 125px);
  }
}
