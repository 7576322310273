body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

.wrongBrowserAlert {
  font-weight: normal;
  padding: 10px;
  text-align: center;
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff;
  font-size: 18px;
}

.cursorPointer {
  cursor: pointer;
}
