@import '../../../assets/styles/variables';

.selectViewContainer {
  display: flex;
  border-bottom: 1px solid #eee;
  margin-top: 15px;
  justify-content: space-around;
  div {
    font-weight: 500;
    color: #363636;
    cursor: pointer;
    position: relative;
  }
  div:hover {
    padding-bottom: 15px;
    border-bottom: 3px solid #004085;
  }
  .activeView {
    //font-weight: 500;
    color: #004085;
    padding-bottom: 15px;
    border-bottom: 3px solid #004085;
  }

  .badge {
    height: 12px;
    width: 12px;
    font-size: 8pt;
    font-weight: bold;
    background: #03A9F4;
    align-items: center;
    display: flex;
    justify-content: center;
    top: -2px;
    right: -25px;
    border-radius: 15px;
    color: #ffffff;
    margin: 3px;
    padding: 3px;
    position: absolute;
  }

}
