.orderFilterContainer {
  .title {
    text-align: center;
    font-weight: bold;
    padding-bottom: 15px;
  }
  .optionBlock {
    display: flex;
    flex-direction: row;
    margin-top: 20px;
  }

  .optionTitle {
    width: 120px;
    margin-top: 8px;
  }

  .emptyRow {
    height: 22px;
  }
}
