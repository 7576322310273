@import '../../../assets/styles/variables';

.textBoxContainer {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  background: #f3f4f6;
  box-shadow: -2px -16px 21px -10px rgba(255,255,255,1);
  .boxWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 10px;
    #chatInput {
      width: 100%;
      font-size: 17px;
      padding: 12px 100px 12px 12px;
      outline: none;
      border: 1px solid #eee;
      resize: none;
      border-radius: 10px;
      box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12);
    }
    .textIcon {
      position: absolute;
      right: 110px;
      cursor: pointer;
    }
    .sendIcon {
      position: absolute;
      right: 65px;
      height: 30px;
      cursor: pointer;
    }
    .flagIcon {
      height: 30px;
      margin-left: 10px;
      cursor: pointer;
    }
  }
}
